import {collection, doc, getFirestore} from 'firebase/firestore'
import {z} from 'zod'
import {timestampType} from "../types/SchemaType";

export const inviteSchema = z.object({
    projects: z.record(
        z.string(),
        z.record(
            z.enum(["association"]),
            z.enum(["talent", "admin", "moderator"])
        )
    ),
    invitedBy: z.string().optional(),
    createdAt: timestampType(),
    usedAt: timestampType().or(z.null()).optional()
})

export type Invite = z.infer<typeof inviteSchema>

export const getInvitesCollection = () => {
    return collection(getFirestore(), "invites")
}

export const getInviteByEmail = (email: string) =>
    doc(getFirestore(), "invites", email)
