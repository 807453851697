import {collection, doc, getFirestore, limit, query, where} from 'firebase/firestore'
import {z} from 'zod'

export const userSchema = z.object({
    access: z.boolean(),
    admin: z.boolean(),
    email: z.string().optional(),
    projects: z.record(
        z.string(),
        z.record(
            z.enum(["association"]),
            z.enum(["talent", "admin", "moderator"])
        )
    )
})

export type User = z.infer<typeof userSchema>

export const getUserById = (userId: string) =>
    doc(getFirestore(), "users", userId)

export const getUserByEmail = (email: string) =>
    query(
        collection(getFirestore(), "users"),
        where("email", "==", email),
        limit(1)
    )
