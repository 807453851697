import {onSnapshot} from 'firebase/firestore'
import {createContext, ReactNode, useContext, useEffect, useState,} from 'react'
import {getProjectsByUserId, Project, projectSchema,} from '../../schemas/Project.schema'
import {WithId} from '../../types/WithId'
import {useAuthentication} from './Authentication'

type ContextElementType = WithId<Project>

const ProjectsContext = createContext<ContextElementType[] | undefined>(
    undefined
)

export default function Projects({ children }: { children?: ReactNode }) {
    const authentication = useAuthentication()
    const [projects, setProjects] = useState<ContextElementType[]>()

    useEffect(() => {
        return onSnapshot(
            getProjectsByUserId(authentication.uid),
            (snapshot) => {
                setProjects(
                    snapshot.docs.map<ContextElementType>((doc) => ({
                        ...projectSchema.parse(doc.data()),
                        id: doc.id,
                    }))
                )
            }
        )
    }, [authentication])

    if (!projects) {
        return (
            <div className="mx-auto container">
                <p>Loading projects...</p>
            </div>
        )
    }

    return (
        <ProjectsContext.Provider value={projects}>
            {children}
        </ProjectsContext.Provider>
    )
}

export const useProjects = () => {
    const context = useContext(ProjectsContext)

    if (!context) {
        throw new Error('useProjectsContext must be within ProjectsProvider')
    }

    return context
}
