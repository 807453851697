import {HTMLAttributes} from 'react'

type ButtonProps = HTMLAttributes<HTMLButtonElement> & {
    disabled?: boolean
    variant: 'primary' | 'success' | 'danger' | 'warning'
    type?: 'submit'
}

export default function Button({
    children,
    variant,
    className,
    disabled,
    ...props
}: ButtonProps) {
    const defaultCSSClasses = 'px-4 py-2 rounded transition-colors'

    const colors: {
        normal: {
            background: string
            text: string
        }
        hover: {
            background: string
            text: string
        }
    } = (() => {
        switch (variant) {
            case 'success':
                return {
                    normal: {
                        // outline-green-600/60 bg-green-600 text-white
                        background: 'green-600',
                        text: 'white',
                    },
                    hover: {
                        // outline-green-700 focus:bg-green-700 hover:bg-green-700
                        background: 'green-700',
                        text: 'white',
                    },
                }
            case 'danger':
                return {
                    normal: {
                        // outline-red-500/60 bg-red-500 text-white
                        background: 'red-500',
                        text: 'white',
                    },
                    hover: {
                        // outline-red-600 focus:bg-red-600 hover:bg-red-600
                        background: 'red-600',
                        text: 'white',
                    },
                }

            case 'warning':
                return {
                    normal: {
                        // outline-orange-500/60 bg-orange-500
                        background: 'orange-500',
                        text: 'white',
                    },
                    hover: {
                        // outline-orange-600 focus:bg-orange-600 hover:bg-orange-600
                        background: 'orange-600',
                        text: 'white',
                    },
                }
            default:
                return {
                    normal: {
                        // outline-teal-500/60 bg-teal-500
                        background: 'teal-500',
                        text: 'white',
                    },
                    hover: {
                        // outline-teal-600 focus:bg-teal-600 hover:bg-teal-600
                        background: 'teal-600',
                        text: 'white',
                    },
                }
        }
    })()

    let componentCSSClasses = `${defaultCSSClasses} bg-${colors.normal.background} text-${colors.normal.text} `

    if (disabled) {
        componentCSSClasses += `bg-opacity-60 cursor-not-allowed `

        delete props.onClick
    } else {
        componentCSSClasses += `focus:bg-${colors.hover.background} hover:bg-${colors.hover.background} focus:text-${colors.hover.text} hover:text-${colors.hover.text} `
    }

    if (className) {
        componentCSSClasses += className
    }

    return (
        <button {...props} className={componentCSSClasses}>
            {children}
        </button>
    )
}
