import {ReactNode, useState} from 'react'
import Center from "./Center";

export default function Tabs({tabs, centerTabs, centerContent}: {
    tabs: [string, ReactNode][],
    centerTabs?: boolean,
    centerContent?: boolean
}) {
    const [currentTab, setTab] = useState<string>(tabs.length ? tabs[0][0] : "");

    if (tabs.length === 0) {
        return <></>
    } else if (tabs.length === 1) {
        return <>
            {tabs[0][1]}
        </>
    }

    // dict of components
    let tabMap = {} as {
        [key: string]: ReactNode
    };
    for (const tab of tabs) {
        tabMap[tab[0]] = tab[1];
    }

    let defaultClass = "text-sm border mr-2 px-2 py-1 rounded opacity-70 hover:opacity-100"
    let selectedClass = defaultClass + " bg-slate-200 text-black"

    let TabsWrapper = centerTabs ? Center : "div";
    let ContentWrapper = centerContent ? Center : "div";

    return (
        <>
            <TabsWrapper className="w-full overflow-x-scroll whitespace-nowrap no-scrollbar">
                {tabs.map(([tabName]) => {
                    return (
                        <button
                            key={tabName}
                            className={currentTab === tabName ? selectedClass : defaultClass}
                            onClick={() => {
                                setTab(tabName);
                            }}
                        >
                            {tabName}
                        </button>
                    )
                })}
            </TabsWrapper>
            <ContentWrapper className="mt-5">
                {tabMap[currentTab]}
            </ContentWrapper>
        </>
    )
}
