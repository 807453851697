import {useEffect, useRef, useState} from "react";
import {WithId} from "../../types/WithId";
import {getQuestionCollection, Question, questionSchema} from "../../schemas/Question.schema";
import {doc, getFirestore, limit, onSnapshot, orderBy, query, updateDoc, where} from "firebase/firestore";
import Center from "../../components/Center";
import {incrementStats} from "../../components/ProjectStats";
import {useQaActive} from "./admin/ConfigView";


const useTalentQuestion = (projectId: string) => {
    const [question, setQuestion] = useState<WithId<Question> | null>()

    useEffect(() => {
        const status: Question['status'] = 'APPROVED'

        return onSnapshot(
            query(
                getQuestionCollection(projectId),
                where('status', '==', status),
                orderBy('createdAt', 'asc'),
                limit(1)
            ),
            (snapshot) => {
                if (snapshot.metadata.fromCache) {
                    return
                }

                if (snapshot.empty) {
                    setQuestion(null)
                } else {
                    setQuestion(
                        snapshot.docs.map<WithId<Question>>((doc) => ({
                            ...questionSchema.parse(doc.data()),
                            id: doc.id,
                        }))[0]
                    )
                }
            }
        )
    }, [projectId])

    return question
}


export const TalentView = ({projectId}: { projectId: string }) => {
    const question = useTalentQuestion(projectId)
    const inputRef = useRef<HTMLInputElement | null>(null)

    const [answer, setAnswer] = useState('')
    const isQaActive = useQaActive(projectId);

    useEffect(() => {
        if (question && inputRef.current) {
            inputRef.current.focus()
        }
    }, [question])

    if (question === undefined) {
        return (
            <Center>
                <p>Loading...</p>
            </Center>
        )
    }

    if (question === null) {
        return (
            <Center>
                <p className="mb-3 text-xl">Question To Answer</p>
                <p>
                    Q&A is <b>{isQaActive ? "" : "not "}accepting questions.</b>
                </p>
                <p>
                    No question available <i>(Will update automatically!)</i>
                </p>
            </Center>
        )
    }

    const questionDoc = doc(
        getFirestore(),
        getQuestionCollection(projectId).path,
        question.id
    )

    return (
        <Center>
            <section className="border border-slate-700 p-5 rounded max-w-xl mx-auto">
                <p className="text-3xl mb-3">
                    "{question.question}"{' '}
                    <button
                        className="float-right text-sm border px-2 py-1 rounded opacity-70 hover:opacity-100"
                        onClick={async () => {
                            const status: Question['status'] = 'SKIPPED'

                            updateDoc(questionDoc, {status}).then(() =>
                                setAnswer('')
                            )

                            await incrementStats(projectId, {
                                skipped: 1
                            })
                        }}
                    >
                        Skip Question
                    </button>
                </p>
                <p className="text-xl text-slate-300 border-b border-slate-700 pb-5">
                    Asked by {question.username}
                </p>

                <input
                    ref={inputRef}
                    className="bg-transparent rounded mt-5 w-full"
                    type="text"
                    placeholder="Put your answer here and hit ENTER"
                    value={answer}
                    onChange={({target: {value}}) => setAnswer(value)}
                    onKeyDown={async (e) => {
                        if (e.key !== 'Enter') {
                            return
                        }

                        const update: Pick<Question, 'status' | 'answer'> = {
                            status: 'ANSWERED',
                            answer,
                        }

                        updateDoc(questionDoc, update).then(() => setAnswer(''))

                        await incrementStats(projectId, {
                            answered: 1
                        })
                    }}
                />
            </section>
        </Center>
    )
}