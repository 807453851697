import {collection, doc, getFirestore} from 'firebase/firestore'
import {z} from 'zod'
import {timestampType} from '../types/SchemaType'

export const questionSchema = z.object({
    createdAt: timestampType(),
    question: z.string(),
    username: z.string(),

    claimedBy: z.string().or(z.null()),

    status: z.enum([
        'UNKNOWN',
        'CLAIMED',
        'APPROVED',
        'ANSWERED',
        'REJECTED',
        'SKIPPED',
        'PENDING'
    ]),

    answer: z.string().or(z.undefined()),
})

export type Question = z.infer<typeof questionSchema>

export const getQuestionReferenceById = (
    projectId: string,
    questionId: string
) => doc(getFirestore(), 'projects', projectId, 'questions', questionId)

export const getQuestionCollection = (projectId: string) =>
    collection(getFirestore(), 'projects', projectId, 'questions')
