import {AskersView} from "./admin/AskersView";
import {AccessView} from "./admin/AccessView";
import {ConfigView} from "./admin/ConfigView";
import {AnswersView} from "./admin/AnswersView";


export const AdminView = ({projectId}: { projectId: string }) => {
    return (
        <div className="max-w-5xl mx-auto grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
            <AccessView projectId={projectId}/>
            <ConfigView projectId={projectId}/>
            {/*<AskersView projectId={projectId}/>*/}
            <AnswersView projectId={projectId}/>

            {/*<section className="border border-slate-700 p-5 rounded ">*/}
            {/*</section>*/}

        </div>
    )
}