import {collection, doc, getFirestore} from 'firebase/firestore'
import {z} from 'zod'

export const askerSchema = z.object({
    selected: z.number(),
    blocked: z.boolean().default(false),
})

export type Asker = z.infer<typeof askerSchema>

export const getAskerRefByUsername = (
    projectId: string,
    username: string
) => doc(getFirestore(), "projects", projectId, "askers", username)

export const getAskerCollection = (projectId: string) =>
    collection(getFirestore(), "projects", projectId, "askers")
