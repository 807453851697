import {useEffect, useState} from "react";
import {WithId} from "../../../types/WithId";
import {
    Question,
    questionSchema,
    getQuestionCollection,
    getQuestionReferenceById
} from "../../../schemas/Question.schema";
import {limit, onSnapshot, orderBy, query, updateDoc, where, deleteDoc} from "firebase/firestore";
import Button from "../../../components/Button";

const COUNT = 15;

function AnsweredQuestion({projectId, question}: { projectId: string, question: WithId<Question> }) {
    const [hover, setHover] = useState(false);

    return (
        <div
            className="py-2 border-b border-slate-800 grid grid-cols-7"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div className="col-span-6">
                <h2 className="font-bold">
                    {question.question}
                </h2>
                <p>
                    {question.answer}
                </p>
            </div>
            <div hidden={!hover}>
                <Button
                    variant="danger" className="w-full h-full"
                    onClick={async () => {
                        await updateDoc(getQuestionReferenceById(projectId, question.id), {
                            "status": "SKIPPED"
                        } as Question)
                    }}
                >
                    X
                </Button>
            </div>
        </div>
    )
}

const useAnsweredQuestions = (projectId: string) => {
    const [answered, setAnswered] = useState<WithId<Question>[]>([]);

    useEffect(() => {
        return onSnapshot(
            query(
                getQuestionCollection(projectId),
                where("status", "==", "ANSWERED"),
                orderBy("createdAt", "desc"),
                limit(COUNT)
            ),
            (snapshot) =>
                setAnswered(
                    snapshot.docs.map((doc) => {
                            console.log(doc.id);
                            return {
                                id: doc.id,
                                ...questionSchema.parse(doc.data())
                            }
                        }
                    )
                )
        )
    }, [projectId])

    return answered
}

export const AnsweredList = ({projectId}: { projectId: string }) => {
    const answered = useAnsweredQuestions(projectId);
    console.log(answered)

    return <div>
        {answered.map((a) => (
            <AnsweredQuestion projectId={projectId} question={a}/>
        ))
        }
    </div>

}


export const AnswersView = ({projectId}: { projectId: string }) => {
    return (
        <div>
            <h1 className="text-xl border-slate-800 border-b py-2 mb-5">Answered Questions</h1>
            <p>Hover over an answer to delete it.</p>
            <AnsweredList projectId={projectId}/>

        </div>
    )
}