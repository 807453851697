import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom'
import AuthenticationContext from './components/contexts/Authentication'
import ProjectsContext from './components/contexts/Projects'
import ProjectContext from './components/contexts/Project'
import Home from './pages/Home'
import Project from './pages/Project'

const App = () => {
    return (
        <BrowserRouter>
            <div className="p-5">
                <AuthenticationContext>
                    <ProjectsContext>
                        <Routes>
                            <Route path="/">
                                <Route index element={<Home/>}/>

                                <Route
                                    path="projects/:projectId"
                                    element={
                                        <ProjectContext>
                                            <Outlet/>
                                        </ProjectContext>
                                    }
                                >
                                    <Route index element={<Project/>}/>
                                </Route>
                            </Route>
                        </Routes>
                    </ProjectsContext>
                </AuthenticationContext>
            </div>
        </BrowserRouter>
    )
}

export default App
