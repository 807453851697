import {collection, doc, getFirestore, query, where} from 'firebase/firestore'
import {z} from 'zod'

export const projectSchema = z.object({
    active: z.boolean(),
    associates: z.array(z.string()),
    image: z.string(),
    title: z.string(),
    ttl: z.number(),
    stats: z.object({
        questions: z.number().optional(),
        pending: z.number().optional(),
        accepted: z.number().optional(),
        rejected: z.number().optional(),
        answered: z.number().optional(),
        skipped: z.number().optional()
    })
})

export type Project = z.infer<typeof projectSchema>

export const getProjectReferenceById = (projectId: string) =>
    doc(getFirestore(), 'projects', projectId)

export const getProjectCollection = () => collection(getFirestore(), 'projects')

export const getProjectsByUserId = (userId: string) =>
    query(getProjectCollection(), where('associates', 'array-contains', userId))
