import {Link} from 'react-router-dom'
import {useProjects} from '../components/contexts/Projects'
import {getAuth} from "firebase/auth";

export default function Home() {
    const projects = useProjects()
    const auth = getAuth()

    return (
        <div className="container mx-auto mt-10">
            <div>
                <button
                    className="float-right text-sm border px-2 py-1 rounded opacity-70 hover:opacity-100"
                    onClick={() => {
                        auth.signOut()
                            .then(() => {
                                window.location.reload();
                            })
                            .catch((err) => {
                                console.log(err);
                            })
                    }}
                >
                    Logout
                </button>
                <h1
                    className="text-3xl font-bold mb-5"
                >Ask by MELON</h1>
            </div>
            <h2 className="mb-5">Projects</h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4">
                {projects.map((project) => (
                    <Link
                        key={`projects-${project.id}`}
                        to={`/projects/${project.id}`}
                        className="flex flex-col bg-slate-800  rounded-lg shadow"
                    >
                        <img src={project.image} alt={project.title}/>
                        <p className="px-4 py-3">{project.title}</p>
                    </Link>
                ))}
            </div>
        </div>
    )
}
