import {createContext, ReactNode, useContext} from 'react'
import {useParams} from 'react-router-dom'
import {Project} from '../../schemas/Project.schema'
import {WithId} from '../../types/WithId'
import {useProjects} from './Projects'

const ProjectContext = createContext<WithId<Project> | undefined>(undefined)

export default function Projects({ children }: { children?: ReactNode }) {
    const projects = useProjects()

    const { projectId } = useParams<{ projectId: string }>()

    const project = projects.find((project) => project.id === projectId)

    if (!project) {
        throw new Error('Could not get project')
    }

    return (
        <ProjectContext.Provider value={project}>
            {children}
        </ProjectContext.Provider>
    )
}

export const useProject = () => {
    const context = useContext(ProjectContext)

    if (!context) {
        throw new Error('useProject must be within ProjectProvider')
    }

    return context
}
