import {JsonValue} from 'type-fest'
import {OK, ParseReturnType, ZodIntersection, ZodObject, ZodType, ZodUnion,} from 'zod'
import type {Timestamp} from 'firebase/firestore'

export namespace SchemaType {
    export type _DocData =
        | ZodObject<any>
        | ZodUnion<any>
        | ZodIntersection<any, any>
    export type _JsonData = _DocData & { _output: JsonValue }
}

export class ZodTimestamp extends ZodType<Timestamp> {
    _parse(input: any): ParseReturnType<Timestamp> {
        const { ctx } = this._processInputParams(input)
        return OK(ctx.data)
    }
}

export const timestampType = () => new ZodTimestamp({})
