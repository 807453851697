import {useEffect, useState} from "react";
import {FieldValue, increment, onSnapshot, updateDoc} from "firebase/firestore";
import {getProjectReferenceById} from "../schemas/Project.schema";

// interface IStats {
//     questions: number,
//     pending: number,
//     accepted: number,
//     rejected: number,
//     answered: number,
//     skipped: number
// }

export function resetStats(projectId: string) {
    return updateDoc(
        getProjectReferenceById(projectId),
        "stats", {
            questions: 0,
            pending: 0,
            accepted: 0,
            rejected: 0,
            answered: 0,
            skipped: 0
        }
    )
}

export function incrementStats(projectId: string, stats: {[key: string]: number}) {
    let update: {
        [key: string]: FieldValue
    } = {};

    for (let stat of Object.keys(stats)) {
        update[`stats.${stat}`] = increment(stats[stat])
    }

    return updateDoc(
        getProjectReferenceById(projectId), update
    )
}

export function useStats(projectId: string) {
    const [stats, setStats] = useState<{ [key: string]: number } | null>(null)

    useEffect(() => {
        if (!projectId) {
            return;
        }

        return onSnapshot(
            getProjectReferenceById(projectId),
            (doc) => {
                if (!doc.exists()) {
                    return;
                }

                setStats(doc.data().stats);
            }
        )
    }, [projectId])

    return stats
}

export default function ProjectStats({projectId, className}: {projectId: string, className?: string}) {
    const stats = useStats(projectId);

    const keys = [
        "Questions",
        "Pending",
        "-",
        ["Approved", "accepted"],
        "Rejected",
        "-",
        "Answered",
        "Skipped"
    ]

    if (!stats) {
        return <p>Loading Stats...</p>
    }

    let i = 0;

    return (
        <div className={"w-full " + (className || "")}>
            {keys.map((v: string | Array<string>) => {
                i++;

                if (v === "-") {
                    return <div className="my-3" key={`-${i}`}></div>
                }

                let statName, statKey;
                if (Array.isArray(v)) {
                    statName = v[0];
                    statKey = v[1];
                } else {
                    statName = v;
                    statKey = v.toLowerCase();
                }

                return <div className="grid grid-cols-12 gap-x-4" key={statKey}>
                    <span className="col-span-6 text-right">{statName + ":"}</span>
                    <span className="text-right font-mono col-span-2">{stats[statKey]}</span>
                </div>
            })}
        </div>
    )
}
