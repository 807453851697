import {ReactNode} from "react";

export default function Center({className, children}: {
    className?: string,
    children: ReactNode
}) {
    return (
        <div className={"container mx-auto max-w-xl " + (className || "") }>
            {children}
        </div>
    )
}
