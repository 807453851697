import {useEffect, useRef, useState} from "react";
import {WithId} from "../../types/WithId";
import {getQuestionCollection, getQuestionReferenceById, Question, questionSchema} from "../../schemas/Question.schema";
import {Asker, getAskerRefByUsername} from "../../schemas/Asker.schema";
import {addDoc, getDoc, getDocs, limit, orderBy, query, setDoc, Timestamp, updateDoc, where} from "firebase/firestore";
import {formatDistanceToNow} from "date-fns";
import Button from "../../components/Button";
import {useAuthentication, useUserData} from "../../components/contexts/Authentication";
import Center from "../../components/Center";
import {incrementStats, useStats} from "../../components/ProjectStats";
import {useQaActive} from "./admin/ConfigView";

// util

function useInterval(callback: () => void, delay: number) {
    let savedCallback = useRef(callback);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        async function tick() {
            if (savedCallback.current.constructor.name === "AsyncFunction") {
                await savedCallback.current();
            } else {
                savedCallback.current();
            }
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

// Maximum amount of questions that can be selected from each Roblox user
const MAX_SELECTED = 2;

const claimQuestion = async (projectId: string, userId: string) => {
    let claimedQuestion;

    while (!claimedQuestion) {
        // get oldest question
        let oldestQuestions = await getDocs(
            query(
                getQuestionCollection(projectId),
                where('status', '==', 'UNKNOWN'),
                orderBy('createdAt', 'asc'),
                limit(1)
            )
        )

        if (oldestQuestions.empty) {
            return
        }
        let oldest = oldestQuestions.docs[0];

        await updateDoc(oldest.ref, {
            status: "PENDING"
        })

        // check if asker is muted or has already been selected
        let askerRef = getAskerRefByUsername(projectId, oldest.data().username);
        let asker = await getDoc(askerRef);
        let data = asker.data();

        if (data) {
            if (!data.blocked && (data.selected < MAX_SELECTED)) {
                // user is still eligible to ask questions, show question to moderator
                claimedQuestion = oldest
            } else {
                // auto-reject questions from users who are blocked
                // or have had questions selected already
                await Promise.all([
                    updateDoc(oldest.ref, {
                        status: "REJECTED",
                    } as Question),
                    incrementStats(projectId, {
                        pending: -1,
                        rejected: 1
                    })
                ])
            }
        } else {
            // asker has had no questions claimed yet, create asker doc and claim question
            await setDoc(askerRef, {
                selected: 0,
                blocked: false
            } as Asker);

            claimedQuestion = oldest
        }
    }

    await updateDoc(
        claimedQuestion.ref, {
            "status": "CLAIMED",
            "claimedBy": userId
        }
    )

    return claimedQuestion
}

const CreateQuestion = ({projectId}: { projectId: string }) => {
    const [executing, setExecuting] = useState(false)

    const create = async () => {
        setExecuting(true);
        const question: Question = {
            createdAt: Timestamp.now(),
            claimedBy: null,
            question: 'Test question',
            status: 'UNKNOWN',
            username: `USER_${Math.floor(Math.random() * 10)}`,
        }

        await addDoc(getQuestionCollection(projectId), question);
        await incrementStats(projectId, {
            questions: 1,
            pending: 1
        })
        setExecuting(false)
    }

    return (
        <Button
            variant="primary"
            className="mb-5"
            onClick={create}
            disabled={executing}
        >
            Create test question
        </Button>
    )
}


const QuestionChoices = ({
                             question,
                             projectId,
                             onChoice
                         }: {
    question: WithId<Question>,
    projectId: string,
    onChoice?: () => void;
}) => {
    const [pending, setPending] = useState(false);

    const onAction = async (questionId: string, status: Question['status'], blockUser?: boolean) => {
        setPending(true);

        let promises = [];

        promises.push(
            updateDoc(getQuestionReferenceById(projectId, questionId), {
                status
            }),
            incrementStats(projectId, {
                pending: -1,
                accepted: (status === "APPROVED") ? 1 : 0,
                rejected: (status === "REJECTED") ? 1 : 0
            })
        );

        const askerRef = getAskerRefByUsername(projectId, question.username);
        if (status === "APPROVED") {

            const askerData = (await getDoc(askerRef)).data() || {
                selected: 0
            };

            promises.push(
                updateDoc(askerRef, {
                    selected: askerData.selected + 1
                } as Asker)
            )
        } else if (blockUser) {
            promises.push(
                updateDoc(askerRef, {
                    blocked: true
                } as Asker)
            )
        }

        await Promise.all(promises);

        if (onChoice) {
            await onChoice();
        }

        setPending(false);
    }


    return (
        <section>
            <p className="mb-3 text-xl">{`${question.username} (${formatDistanceToNow(
                question.createdAt.toDate()
            )} ago)`}</p>

            <p>
                {question.question}
            </p>

            <div className="flex flex-row gap-3 mt-4">
                <Button
                    variant="success"
                    className="flex-1"
                    disabled={pending}
                    onClick={async () => {
                        await onAction(question.id, "APPROVED");
                    }}
                >
                    Approve
                </Button>
                <Button
                    variant="warning"
                    className="flex-1"
                    disabled={pending}
                    onClick={async () => {
                        await onAction(question.id, "REJECTED");
                    }}
                >
                    Deny
                </Button>
                <Button
                    variant="danger"
                    className="flex-2"
                    disabled={pending}
                    onClick={async () => {
                        if (!window.confirm(`Block questions from ${question.username}?`)) {
                            return;
                        }
                        await onAction(question.id, "REJECTED", true);
                    }}
                >
                    Block
                </Button>
            </div>
        </section>
    )
}

export const ModeratorView = ({projectId}: { projectId: string }) => {
    const user = useAuthentication();
    const myData = useUserData();
    const isQaActive = useQaActive(projectId);

    const [currentQuestion, setCurrentQuestion] = useState<WithId<Question> | null>(null);
    const [active, setActive] = useState(false);
    const stats = useStats(projectId);

    // claims oldest unanswered question for moderator
    const getQuestion = async () => {
        let q = await claimQuestion(projectId, user.uid);

        if (!q) {
            setCurrentQuestion(null);
            return;
        }

        setCurrentQuestion({...questionSchema.parse(q.data()), id: q.id});
        console.log(q.data());
    }


    const onActiveChange = async (nowActive: boolean) => {
        if (nowActive) {
            await getQuestion();
        } else {
            if (currentQuestion) {
                // change status back to unknown to pass to another moderator
                await updateDoc(
                    getQuestionReferenceById(projectId, currentQuestion.id),
                    {
                        "status": "UNKNOWN",
                        "claimedBy": null
                    } as Question
                )
            }
        }
    }

    // periodically check for new questions while question is null
    // chose to do this over using onSnapshot bc in a case where
    // all moderators are waiting on a question to be asked
    // that could create race condition
    useInterval(async () => {
        if (active && currentQuestion === null) {
            await getQuestion();
        }
    }, 3000)

    let startButtonCol = active ? "green-500" : "red-500"

    return (
        <Center>
            {myData?.admin ?
                <CreateQuestion projectId={projectId}/>
                : null
            }
            {/*className={`text-${isQaActive ? "green-500" : "red-500"}`}*/}
            <p>
                Q&A is <b >{isQaActive ? "" : "not "}accepting questions.</b>
            </p>
            <p>
                Pending questions: {stats ? stats.pending : "?"}
            </p>
            <br/>

            <p className="h-10 text-lg">
                Question Approval
                <button
                    className="float-right text-sm border px-2 py-1 rounded opacity-70 hover:opacity-100"
                    onClick={async () => {
                        setActive(!active);
                        await onActiveChange(!active);
                    }}
                >
                    {
                        active ? "Stop" : "Start"
                    }
                </button>
            </p>

            <section className="border border-slate-700 p-5 rounded ">
                {
                    active ? (
                        currentQuestion ? (
                            <QuestionChoices
                                question={currentQuestion}
                                projectId={projectId}
                                onChoice={async () => {
                                    await getQuestion();
                                }}
                            />
                        ) : (
                            <p>There's no questions right now. Questions will appear when asked.</p>
                        )
                    ) : (
                        <>
                            <p>
                                Click "Start" to approve questions. You will
                                have options to Accept or Reject, as well as the option
                                to block all questions from a user.
                            </p>
                            <br/>
                            <p>
                                Once started, click "Stop" to pass the current question
                                to another moderator and stop receiving questions.
                            </p>
                        </>
                    )
                }

            </section>

        </Center>
    )
}