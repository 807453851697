import {
    fetchSignInMethodsForEmail,
    getAuth,
    isSignInWithEmailLink,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    User
} from 'firebase/auth'
import {createContext, FormEvent, ReactNode, useContext, useEffect, useState,} from 'react'
import {useAuthState} from 'react-firebase-hooks/auth'
import Alert from '../Alert'
import Button from '../Button'
import {useNavigate} from "react-router-dom";
import {getDoc} from "firebase/firestore";
import {getUserById, User as UserT, userSchema} from "../../schemas/User.schema";
import {getInviteByEmail} from "../../schemas/Invite.schema";

const AuthenticationContext = createContext<User>(undefined as unknown as User)

function Login() {
    const [email, setEmail] = useState('')
    const [status, setStatus] = useState(0)
    const navigate = useNavigate();

    const formSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if (status !== 0) {
            return
        }

        setStatus(1)

        let hasAccess = false;

        try {
            let options = await fetchSignInMethodsForEmail(getAuth(), email);

            if (options.length > 0 || email.endsWith("@melonverse.com") || email.endsWith("@melon.dev")) {
                hasAccess = true;
            } else {
                // check if user has an invite

                let invite = (await getDoc(
                    getInviteByEmail(email)
                ));

                if (invite.exists()) {
                    hasAccess = true;
                }
            }
        } catch (e) {
            console.log(e);
        }

        if (!hasAccess) {
            alert("You don't have access to Ask.");
            window.location.reload();
        }


        try {
            await sendSignInLinkToEmail(getAuth(), email, {
                url: window.location.origin,
                handleCodeInApp: true,

            })
        } catch {
        }
        setStatus(2)
    }

    const auth = getAuth();
    const isConfirming = isSignInWithEmailLink(auth, window.location.href)
    const confirmEmail = async (e: FormEvent) => {
        e.preventDefault();

        try {
            await signInWithEmailLink(auth, email, window.location.href);

            navigate("/")
        } catch {
        }
    }

    if (status === 0) {
        return (
            <div className="container mx-auto max-w-lg">
                <h1
                    className="text-3xl font-bold mb-5 mt-10"
                >Ask by MELON</h1>
                <form onSubmit={isConfirming ? confirmEmail : formSubmit}>
                    <div className="grid grid-cols-7">
                        <div className="flex flex-col col-span-4 md:col-span-5">
                            <input
                                className="bg-transparent rounded-lg rounded-r-none w-full border-slate-700"
                                type="email"
                                required
                                placeholder="email@domain.com"
                                value={email}
                                onChange={({target: {value}}) =>
                                    setEmail(value)
                                }
                            />
                        </div>

                        <Button variant="primary" className="rounded-lg rounded-l-none col-span-3 md:col-span-2">
                            {isConfirming ? "Confirm Email" : "Log in"}
                        </Button>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div className="container mx-auto max-w-lg">
            <Alert variant="primary">
                {status === 1
                    ? 'Sending email...'
                    : 'Email has been sent to your inbox. Remember to check your spam folder. This window can be closed now!'}
            </Alert>
        </div>
    )
}

export default function Authentication({children}: { children?: ReactNode }) {
    const [user, isLoading] = useAuthState(getAuth(), {})

    if (isLoading || user === undefined) {
        return (
            <div className="container mx-auto max-w-lg">
                <p>Loading...</p>
            </div>
        )
    }

    if (user === null) {
        return <Login/>
    }

    return (
        <AuthenticationContext.Provider value={user}>
            {children}
        </AuthenticationContext.Provider>
    )
}

export const useAuthentication = () => useContext(AuthenticationContext)

export function useUserData() {
    const user = useAuthentication();
    const [userData, setUserData] = useState<UserT | null>(null);

    useEffect(() => {
        if (!user) {
            setUserData(null);
        }

        try {
            let userDoc = getUserById(user.uid);

            getDoc(userDoc).then((user) => {
                setUserData(userSchema.parse(user.data()) || null);
            })
        } catch (e) {
            console.log(e);
        }


    }, [user]);

    return userData
}
