import {useEffect, useRef, useState} from "react";
import {onSnapshot, updateDoc} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions"
import {getProjectReferenceById, projectSchema} from "../../../schemas/Project.schema";
import Button from "../../../components/Button";
import ProjectStats, {resetStats} from "../../../components/ProjectStats";

export function useQaActive(projectId: string) {
    const [active, setActive] = useState(false);

    useEffect(() => {
        return onSnapshot(getProjectReferenceById(projectId), (snapshot) => {
            setActive(
                projectSchema.parse(snapshot.data()).active
            )
        })
    }, [projectId]);

    return active
}

function QuestionsStatus({projectId, className}: {projectId: string, className?: string}) {
    const active = useQaActive(projectId);

    return (
        <div className={className || ""}>
            <p>Q&A session is <b>{active ? "" : "not "}active.</b></p>
            <Button
                className="w-full mt-2"
                variant={active ? "danger" : "success"}
                onClick={async () => {
                    if (!window.confirm(`${active ? 'Stop' : 'Start'} Q&A?`)) {
                        return
                    }

                    await updateDoc(
                        getProjectReferenceById(projectId),
                        {
                            "active": !active
                        }
                    )
                }}
            >
                {active ? "Stop" : "Start"} Q&A
            </Button>
        </div>
    )
}


const getToken = httpsCallable(getFunctions(), "getTokenForProject");

function TokenView({projectId}: {projectId: string}) {
    const [token, setToken] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const tokenRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (tokenRef.current) {
            tokenRef.current.select();
        }
    }, [token])

    return token ? (
        <input
            type="text" readOnly
            className="w-full bg-transparent border-slate-700 border px-4 py-2 rounded" value={token}
            ref={tokenRef}
        />
    ) : (
        <Button
            className="w-full"
            variant="primary"
            disabled={loading}
            onClick={async () => {
                setLoading(true);

                try {
                    let resp = await getToken(projectId)
                    let token = resp.data as string || null;
                    setToken(token)
                } catch (e) {
                    alert("You must be an Ask admin to see the project token.");
                }
            }}
        >
            Get API Token
        </Button>
    )

}

function ResetQuestions({projectId}: {projectId:string}) {
    const active = useQaActive(projectId);

    return <Button
        variant="danger" disabled={active} className="w-full mb-4"
        onClick={async () => {
            if (active) {
                alert("Can't reset questions while Q&A is active.");
            }

            if (!window.confirm("This will reset stats. Are you sure?")) {
                return;
            }

            await Promise.all([
                resetStats(projectId)
            ])
        }}
    >
        Reset Stats
    </Button>
}

export const ConfigView = ({projectId}: { projectId: string }) => {
    return (
        <div>
            <h1 className="text-xl border-slate-800 border-b py-2 mb-5">Manage Project</h1>

            {/*<section className="border border-slate-700 p-5 rounded ">*/}
            {/*</section>*/}

            <QuestionsStatus projectId={projectId} />

            <hr className="border-t border-slate-800 my-4"/>

            <h2 className="mb-2">Roblox API Token</h2>
            <TokenView projectId={projectId} />

            <hr className="border-t border-slate-800 my-4"/>

            <ResetQuestions projectId={projectId} />
            <ProjectStats projectId={projectId} />

        </div>
    )
}