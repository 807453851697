import {initializeApp} from 'firebase/app'

initializeApp({
    apiKey: 'AIzaSyAhwtWAeWRslRnjD0j0o4HQJifqy0uxpMs',
    authDomain: 'ask-by-melon.firebaseapp.com',
    projectId: 'ask-by-melon',
    storageBucket: 'ask-by-melon.appspot.com',
    messagingSenderId: '400275661072',
    appId: '1:400275661072:web:2dbf3485e82defb4ff8595',
    measurementId: 'G-W1HFXPQ6FD',
});
