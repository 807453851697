import Tabs from "../components/Tabs";
import {useProject} from '../components/contexts/Project'
import {ModeratorView} from "./project/Moderator";
import {TalentView} from "./project/Talent";
import {useNavigate} from "react-router-dom";
import {useAuthentication, useUserData} from "../components/contexts/Authentication";
import {ReactNode} from "react";
import {AdminView} from "./project/Admin";
import Center from "../components/Center";


export default function Project() {
    const project = useProject()
    const navigate = useNavigate();
    const user = useAuthentication();
    let userData = useUserData();

    let association = "";
    if (userData) {
        association = userData.projects[project.id].association || ""
    }

    const tabs: [string, ReactNode][] = []

    if (userData?.admin || ["talent", "admin"].includes(association)) {
        tabs.push([
            "Talent", <TalentView projectId={project.id}/>
        ])
    }

    if (userData?.admin || ["moderator", "admin"].includes(association)) {
        tabs.push([
            "Moderator", <ModeratorView projectId={project.id}/>
        ])
    }
    if (userData?.admin || association === "admin") {
        tabs.push([
            "Admin", <AdminView projectId={project.id}/>
        ])
    }

    return (
        <div>
            <Center className="my-5">
                <h1 className="text-3xl">{project.title}</h1>
                <a
                    className="text-slate-400 hover:cursor-pointer"
                    onClick={() => {
                        navigate("/");
                    }}
                >Back to Projects</a>
            </Center>
            {
                association ? <Tabs tabs={tabs} centerTabs/> : <Center>
                    <p>You don't have access to this project.</p>
                </Center>
            }

        </div>
    )
}
