import {useEffect, useState} from "react";
import {
    arrayRemove,
    arrayUnion,
    deleteField,
    getDocs,
    onSnapshot,
    serverTimestamp,
    setDoc,
    updateDoc
} from "firebase/firestore";
import Button from "../../../components/Button";
import {getProjectReferenceById, projectSchema} from "../../../schemas/Project.schema";
import {getUserByEmail, getUserById, userSchema} from "../../../schemas/User.schema";
import {useAuthentication} from "../../../components/contexts/Authentication";
import {getInviteByEmail, inviteSchema} from "../../../schemas/Invite.schema";

// todo refactor

export function useAssociates(by: "id", projectId: string) {
    interface IAssociates {
        [key: string]: {
            id: string,
            email?: string,
            isAdmin: boolean,
            association: string
        }
    }

    const [associations, setAssocations] = useState<IAssociates>({});
    const [users, setUsers] = useState<string[]>([]);

    // first keep track of users associated with project
    useEffect(() => {
        return onSnapshot(getProjectReferenceById(projectId), (doc) => {
            let project_users = projectSchema.parse(doc.data()).associates
            setUsers(
                project_users
            );

            // detect deleted users
            let delete_users = [];
            for (let [_, val] of Object.entries(associations)) {
                if (!project_users.includes(val.id)) {
                    delete_users.push(val[by]);
                }
            }

            if (delete_users.length > 0) {
                for (let uid of delete_users) {
                    delete associations[uid]
                }

                setAssocations({
                    ...associations
                })
            }

        })
    }, [projectId])

    // then keep track of each user's affiliations with project
    useEffect(() => {
        let cleanups: (() => void)[] = [];

        for (let userId of users) {
            cleanups.push(
                onSnapshot(getUserById(userId), (snapshot) => {
                    if (!snapshot.exists()) {
                        return
                    }

                    let userData = userSchema.parse(snapshot.data());

                    if (!userData) {
                        return
                    }

                    let data = {
                        id: userId,
                        email: userData.email,
                        isAdmin: userData.admin,
                        association: userData.projects[projectId]?.association || ""
                    }

                    let key = data[by];
                    associations[key] = data;

                    setAssocations({
                        ...associations
                    })
                })
            )
        }

        return () => {
            for (let cleanup of cleanups) {
                cleanup();
            }
        }
    }, [projectId, users])

    return associations
}

export const AccessView = ({projectId}: { projectId: string }) => {
    const associates = useAssociates("id", projectId);
    const me = useAuthentication();

    const [inputUser, setInputUser] = useState("");
    const [addingUser, setAddingUser] = useState(false);

    const addAccess = (userId: string, association: string) => {
        return Promise.all([
            updateDoc(
                getUserById(userId), {
                    [`projects.${projectId}.association`]: association
                }
            ),
            updateDoc(
                getProjectReferenceById(projectId), {
                    "associates": arrayUnion(userId)
                }
            )
        ])
    }

    const modifyAccess = (userId: string, association: string) => {
        return updateDoc(
            getUserById(userId),
            {
                [`projects.${projectId}.association`]: association
            }
        )
    }

    const removeAccess = (userId: string) => {
        return Promise.all([
            updateDoc(
                getUserById(userId),
                {
                    [`projects.${projectId}`]: deleteField()
                }
            ),
            updateDoc(
                getProjectReferenceById(projectId),
                {
                    "associates": arrayRemove(userId)
                }
            )
        ])
    }

    const addUserCB = async () => {
        if (!inputUser) {
            return
        }

        setAddingUser(true);

        // input takes email or user ID
        // user ID will never have @ in it

        let userId;
        if (inputUser.includes("@")) {
            let userByEmail = await getDocs(getUserByEmail(inputUser));

            if (userByEmail.docs.length === 0) {
                // no user found create invite
                if (window.confirm(`${inputUser} isn't on Ask. Do you want to invite them to this project?`)) {
                    await setDoc(
                        getInviteByEmail(inputUser),
                        inviteSchema.parse({
                            projects: {
                                [projectId]: {
                                    association: "moderator"
                                }
                            },
                            invitedBy: me.uid,
                            createdAt: serverTimestamp(),
                            usedAt: null
                        })
                    )

                    alert(`When ${inputUser} signs in they will be added to this project.`)
                }
                setAddingUser(false);
                setInputUser("");
                return
            }

            userId = userByEmail.docs[0].id
        } else {
            alert(`${inputUser} isn't a valid email.`)
            return;
        }

        if (associates[userId] !== undefined) {
            setAddingUser(false);
            setInputUser("");
            return;
        }

        try {
            await addAccess(userId, "moderator");
        } catch (e) {
            console.log(e);
        }

        setInputUser("");
        setAddingUser(false);
    }

    return (
        <div>
            <h1 className="text-xl border-slate-800 border-b py-2 mb-5">Manage Access</h1>

            <div className="bg-transparent mt-0 mb-2 w-full grid grid-cols-7 rounded-lg p-0">
                <input
                    className="bg-transparent rounded-lg rounded-r-none w-full col-span-6 border-r-0
                               border-slate-700"
                    type="text"
                    placeholder="email@domain.com"
                    value={inputUser}
                    onChange={({target: {value}}) => setInputUser(value)}
                    onKeyDown={async (e) => {
                        if (e.key !== "Enter") {
                            return;
                        }

                        await addUserCB();
                    }}
                />
                <Button
                    className="rounded-lg rounded-l-none border border-l-none border-green-700
                               text-lg"
                    variant="success"
                    disabled={addingUser}
                    onClick={addUserCB}
                >+</Button>
            </div>

            {
                Object.keys(associates).sort((a, b) => {
                    return (associates[a].email || "") > (associates[b].email || "") ? 1 : -1;
                }).map((id) => {
                    let data = associates[id];
                    let email = data.email || null

                    return <div className="mb-2" key={id}>
                        <div className="bg-slate-800 rounded-t-lg border border-slate-700">
                            <Button
                                variant="danger" className="float-right py-2 rounded-none rounded-tr-lg
                                                    border-l border-slate-700"
                                onClick={async () => {
                                    if (window.confirm(`Remove ${email} from project?`)) {
                                        await removeAccess(id);
                                    }
                                }}
                                disabled={id === me.uid || data.isAdmin}
                            >
                                X
                            </Button>
                            <p className="px-3 py-2 overflow-ellipsis">{email || id}</p>
                        </div>
                        <div className="p-2 border-l border-r border-b rounded-b-lg border-slate-700">
                            <div className="grid grid-cols-3 gap-2">
                                <span className="leading-10 text-right">role:</span>
                                <select
                                    className="bg-transparent rounded-md border-slate-700 col-span-2"
                                    value={data.association}
                                    disabled={
                                        id === me.uid
                                    }
                                    onChange={async (e) => {
                                        await modifyAccess(data.id, e.target.value)
                                    }}
                                >
                                    <option value="talent">Talent</option>
                                    <option value="moderator">Moderator</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </div>
                        </div>
                    </div>
                })
            }

            {/*<section className="border border-slate-700 p-5 rounded ">*/}
            {/*</section>*/}

        </div>
    )
}