import {ReactNode} from 'react'

export default function Alert({
    children,
    variant,
    className,
}: {
    children?: ReactNode
    className?: string
    variant: 'success' | 'warning' | 'danger' | 'primary'
}) {
    let componentClassName = 'p-4 rounded'

    switch (variant) {
        case 'success':
            componentClassName = `${componentClassName} bg-green-600 text-white`
            break
        case 'danger':
            componentClassName = `${componentClassName} bg-orange-500 text-white`
            break
        case 'warning':
            componentClassName = `${componentClassName} bg-red-500 text-white`
            break
        case 'primary':
            componentClassName = `${componentClassName} bg-teal-500 text-white`
            break
    }

    if (className) {
        componentClassName = `${componentClassName} ${className}`
    }

    return <div className={componentClassName}>{children}</div>
}
